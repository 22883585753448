<template>
    <el-container>
        <el-header>
            <el-row type="flex" class="row-bg">
                <el-col :span="12">
                    <h3 class="grunau left">Arna Alkierios</h3>
                </el-col>
                <el-col :span="12">
                    <br>
                    [sgs] [en] [lt]
                </el-col>
            </el-row>
        </el-header>

        <el-main>
            <el-row v-if="tags" class="flex flex-wrap items-center mb-4">
                <el-button v-bind:key="tag" v-for="(_, tag) in tags" type="info" link>
                    #{{ tag }}
                </el-button>
            </el-row>

            <el-row v-if="categories" class="flex flex-wrap items-center mb-4">
                <div v-bind:key="category" v-for="(qty, category) in categories" class="z-category" style="border-color:
                #f00; color: #f00">
                    /{{ category }}
                </div>
            </el-row>

            <el-row v-if="files">
                <div v-bind:key="file.id" v-for="file in files" class="z-page">
                    <el-text v-bind:key="cat" v-for="cat in file.category">/{{ cat }}</el-text><br>
                    <el-text size="large" tag="b">{{ file.description }}</el-text><br>
                    <el-button v-bind:key="tag" v-for="tag in file.tags" type="info" link>#{{ tag }}</el-button><br>
                    <el-text type="info">{{file.created}} · {{file.lang}}</el-text>
                </div>
            </el-row>
        </el-main>
    </el-container>
</template>

<script>

// TODO: markdown https://vuejsexamples.com/a-powerful-markdown-components-for-vue/
export default {
    components: {

    },
    data: () => ({
        tags: {},
        files: {},
        categories: {},
        search: '',
    }),
    methods: {
        loadTree() {
            this.$http.get(
                '/f',
            ).then((response) => {
                this.tags = response.data.tags;
                this.files = response.data.files;
                this.categories = response.data.categories;
            });
        },
    },
    created() {
        this.loadTree();
    },
};
</script>

<style lang="scss" scoped>
.el-container {
    @font-face {
        font-family: Andika;
        src: url('./../public/fonts/Andika-Regular.woff2');
        src: url('./../public/fonts/Grunau.woff2');
    }

    // font-family: Andika;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .grunau {
        font-family: Grunau;
    }

    .el-header {
        background-color: #E3FFD7;
    }

    .z-category {
        font-weight: bold;
        display: inline-block;
        padding: 5px;
        margin: 3px;
        border: 2px solid #ddd;
    }

    .z-page {
        display: inline-block;
        padding: 5px;
        margin: 3px;
        border: 2px solid #ddd;
    }
}
</style>
